import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import thumb from "assets/img/bg/bg-image-2.jpg";
import Section from 'components/HOC/Section';
import ListItem from "components/list";
import listContent from '../../data/list';

const Service = () => {
    return (
        <Section id="ourServices">
        <div className="ptb--100 ptb-sm--60 ptb-md--80 bg_color--1">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="title--default text-center">
                            <h6 className="heading heading-h6 theme-color">What we do</h6>
                            <div className="separator--30"/>
                            <h3 className="heading heading-h3 line-height-1-5 wow move-up">Accounting Services</h3>
                        </div>
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col lg={8} md={12} sm={12}>
                        <div className="about-inner authentic-about">
                            <div className="separator--60"/>
                            <p className="paragraph font-18 line-height-1-6 wow move-up">
                            We are committed to helping you clear the fog of your 
                            tax obligations and free up valuable time to focus on 
                            the operation and growth of your business. Regardless 
                            of the size of your business, we offer professional 
                            advice on what you need to achieve your goals. 
                            Whether it is implementing business strategy, 
                            increasing cash flow, improving profits, or providing 
                            compliance advice and business growth, we are always 
                            ready to provide you with the best long-term service.
                            </p>
                            <div className="separator--60"/>
                            <div className="ml--60 list">
                                {listContent.map(item=>(
                                    <ListItem
                                        key={item.id}
                                        marker={'check'}
                                        text={item.text}
                                    />
                                ))}
                            </div>
                        </div>
                    </Col>

                    <Col lg={4} md={12} sm={12}>
                        <div className={`thumb text-left wow move-up`}>
                            <img src={thumb} alt="thumb"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </Section>
    );
};

export default Service;
