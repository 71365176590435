import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Section from 'components/HOC/Section';

const ContactOne = () => {
    return (
        <Section id="contactUs">

            <Container>
                <Row className='mb--60'>
                    <Col lg={12}>
                        <div className="title--default text-center">
                            <div className="separator--30"/>
                            <h3 className="heading heading-h3 line-height-1-5 wow move-up">Contact Us</h3>
                            <div className="separator--30"/>
                        </div>
                    </Col>

                    {data.map(item=>(
                        <Col key={item.id} md={6} lg={4} className="wow move-up mt--40">
                            <div className="classic-address">
                                <h5 className="heading heading-h5 font-20">{item.title}</h5>
                                <div className="desc mt--15">
                                    <p className="paragraph line-height-2-22" dangerouslySetInnerHTML={{__html: item.content}}/>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Section>
    );
}

const data = [
    {
        id: 1,
        title: "Phone",
        content: "+61 429 771 643"
    },
    {
        id:2,
        title:"Email",
        content: "accounting@ylgroup.au"
    },
    {
        id: 3,
        title: "Address",
        content: "U 2004/433 Collins Street, Melbourne VIC 3000"
    },
]

export default ContactOne;
