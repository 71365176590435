import React from 'react';
import Section from 'components/HOC/Section';

const IconBoxItem = () => {
    return (
        <Section id="aboutUs">
            <div className="icon-box mt--30">
                <div className="inner">
                    <div className="content">
                        <div className="section-title text-center">
                            <h3>About Us</h3>
                        </div>
                        <p className="paragraph font-20">
                            <b className='font-22'>YL Accounting</b> has a long history of expertise in tax 
                            planning, tax benchmarking, corporate financial control, 
                            asset and capital share management and planning. <br/><br/>
                            Our team of certified public accountants will bring 
                            their years of experience and expertise to provide 
                            you with professional advice and advice on tax, 
                            consulting, compliance and other issues.
                            We will help you and your business structure or 
                            business restructuring formulate. <br/>
                        </p>
                    </div>
                </div>
            </div>
        </Section>
        
    );
};

export default IconBoxItem;