import React from 'react';

const ListItem = ({text, content}) => {
    return (
        <div className="list-header with-check">
            <div className="marker"/>
            <div className="title-wrap">
                <h5 className="heading heading-h5 font-20">{text}</h5>
            </div>
        </div>
    );
};

export default ListItem;
