import React from 'react';

import FixedLink from 'components/fixedLink/FixedLink';
import navbarData from "data/navbar"

const NavbarItem = () => {
    return (
        navbarData.map(navbar => (
            <li key={navbar.id}
                className={`label-1 ${navbar.megaMenu} ${navbar.megaMenu ? 'slide--megamenu' : ''}`}>
                <FixedLink target={`${navbar.target}`}><span>{navbar.title}</span></FixedLink>
            </li>
        ))
    );
};

export default NavbarItem;