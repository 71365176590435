import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import PageContainer from "container/page";
import Header from "components/header/Header";
import PopupMobileMenu from "components/popup/PopupMobileMenu";
import Breadcrumb from "components/breadcrumb/BreadcrumbFive";
import IconBoxes from "container/icon-box/pages/IconBoxOne";
import Service from "container/work/Service";
import Contact from "container/contact/Contact";
import Footer from "container/footer/FooterOne";

const Home = () => {
    return (
        <Fragment>
            <Helmet>
                <title>YL Accounting</title>
            </Helmet>

            <PageContainer classes="template-color-1 template-font-1" revealFooter={false}>
                <Header/>
                <PopupMobileMenu/>
                <Breadcrumb
                    title={"YL Accounting"}
                />
                <IconBoxes/>
                <Service/>    
                <Contact/>         
                <Footer/>
            </PageContainer>
        </Fragment>
    );
};

export default Home;